@import "../../assets/global";
@import "../../assets/variables";
@import "../../assets/mixins";

.Gallery{@include sec-default();  padding: 30px 0 60px; text-align: center;
  position: relative; z-index: 0; &::before{@include before(); background-color: rgba($color1, .2); height:400px;}
  
  .intro{@include sec-default(); text-align: center; margin-bottom: 60px;
    .wcgLink{width:200px; background-color: $color2;}
  }
  h1{font-size: 1.5em; margin: 30px 0 0px; font-family: $nanum;}


  .newGall{@include sec-default();}
  .galleryPhoto{display:inline-block; width:60%; margin:.25%;}
  .galleryPhoto{float:left;
    &.tall, &.tallRight{width: 39%;}
    &.long{width:70.35%;}
    &.thin{width:28.65%;}
    &.thin3{width:24.5%;}
    &.hlf{width:49.5%;}
    // &.mid{width:41.7%;}
    &.tallRight {float: right;}
  }

  @include bp(tb) {
    .intro{margin-bottom: 30px;}
    h1{margin:0 0 0;}
  }
  
  @include bp(oc) {
    .intro br{display: none;}
  }
  
  @include bp(sm) {
    .galleryPhoto{
      &.reg, &.tall, &.tallRight, &.long, &.thin, &.thin3, &.hlf, &.tallRight {@include sec-default();margin:5px 0;
    }
    img{width: 90%; @include center-block();}
    }
  }
}