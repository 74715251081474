@import "../../assets/global";
@import "../../assets/variables";
@import "../../assets/mixins";

.MenuPage{@include sec-default();
  .MenuHeader{width:100%;height:300px;display:flex;align-items:center;justify-content:center;text-align:center;
   background-repeat:no-repeat;position:relative;z-index:0;background-size:100%;
   &::before{@include before();background-color:rgba($black,.4);}
    .wcgLink{background-color:$color2;width:150px;box-shadow:0px 10px 20px rgba($black,.2);}
  }
  h1{font-family:$nanum;font-size:35px;line-height:1em;margin-bottom:15px;
    color:$white;text-shadow:0px 1px 1px rgba($black,.9);
    span{color:$melon-lt;font-family:$dance;font-size:30px;}
  }

  .MenuSec{@include sec-default();padding:40px 0;text-align:center;
    .insideNrw{width:85%;max-width:600px;}
    hr{max-width:200px;@include center-block();margin:30px auto;}
    h3,h4{font-weight:bold;font-family:$dance;}
    h3{font-size:22px;margin-bottom:40px;}
    h4{font-size:26px;color:$color1;margin:0;text-decoration:underline;}
    p{font-size:18px;margin:0 0 12px;padding-top:15px;position:relative;z-index:0;
      &::before{@include before();width:5px;height:5px;left:50%;margin-left:-2.5px; border-radius:50%;background-color:rgba($melon,.7);}
    }
    h4+p{padding-top:0;
      &::before{display:none;}
    }
  }
  
  @include bp(tb) {
    .MenuHeader{height:300px;
      &::before{background-color:rgba($black,.5);}
    }
    .MenuSec{padding:20px 0 30px;
      hr{margin:25px auto;}
    }
  }

  @include bp(og) {
    .MenuHeader{height:200px;}
    h1{font-size:30px;
      span{font-size:25px;}
    }
  }

  @include bp(oc) {
    .MenuHeader {
      &::before{background-color:rgba($black,.65);}
      .wcgLink{padding:7px 10px;width:135px;}
    }
    h1{font-size:25px;
      span{font-size:22px;}
    }
    .MenuSec{
      hr{max-width:150px;margin:20px auto;}
      h3{font-size:20px;margin-bottom:30px;}
      h4{font-size:23px;}
      p{font-size:17px;}
    }
  }
}