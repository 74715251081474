@import "./assets/global";
@import "./assets/variables";
@import "./assets/mixins";

.ToTopBtn{background-color: $white; margin-right:-15px; margin-bottom: 20px;}
main {@include sec-default();padding: 0 0px;}
.wcgroupWrap {@include sec-default();}

.ocNav{z-index:99999}
.ocBar{background-color: $cream; border-right: 1px solid rgba($black, .3);
  a, button {color: $color2; font-family: inherit; padding: 0;
    font-size: 20px;
    &:hover {color: $color1;}
  }
  li{margin-bottom: 10px;}
  .navClose {color: $black;}
}
