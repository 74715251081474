@import "../../assets/global";
@import "../../assets/variables";
@import "../../assets/mixins";

.Home {@include sec-default();
  .insideFull{width: 98%; @include center-block();}
  button{padding:0;font-family: inherit; text-align: left; line-height: 1.5em;
    &:hover {cursor: pointer;}
  }

  .heroMenu{@include sec-default(); text-align: center; 
    .hmItem {float:left;}
    .hmItem {display: inline-block; vertical-align: top;
      width:33.2%; width:24.8%; height: 300px; margin-right:.26%;
      @include sec-bgimage('./images/navImgHome0.jpg'); background-size: cover; position: relative; z-index: 0;
      &:hover {cursor: pointer; opacity: .7;}
      &::before{@include before(); background-color: rgba($black,.1);}
      &.gallery{background-image: url('../../assets/images/navImgGallery2.jpg');}
      &.menus{background-image: url('../../assets/images/navImgMenu0.jpg');}
      &.about{background-image: url('../../assets/images/tray.jpg');}
      
      &:last-child{float:right; margin-right:0}
      
      .hmText{color: $white; font-weight: 800; font-size: 1.4em; @include tshadowc(0px 1px 2px, rgba($black,.9));
        position: absolute; bottom:7px; width:100%; left:0;
        
        font-family: $nanum; font-size: 1.3em; letter-spacing: .5px;
      }
    }
    button.hmItem{padding:0;font-family: inherit; text-align: center;}
    
    @include bp(tb) {
      .hmItem {height:250px;
        &::before{@include before();background-color: rgba($black,.25);}
        .hmText{font-size:1.2em;letter-spacing: .2px;}
      }
    }
    
    @include bp(tn){
      .hmItem {height:200px;
        .hmText{font-size:1em;}
      }
    }
    
    @include bp(oc){
      .hmItem {height:150px;}
    }
    
    @include bp(sm){
      .hmItem {@include sec-default(); height:100px; background-position: center center; margin-bottom: 10px;
        &::before{background-color: rgba($black, .5);}
        .hmText{bottom:30%; font-size:1.2em;}
        
        // &.gallery{margin: 10px 0;}
      }
    }
  }
  
  .homeWhoV2b {@include sec-default(); margin-top:30px; padding:80px 0 80px;
    h1{color: $color1; color:$melon; font-weight: 800; font-family: $nanum; letter-spacing: .3px; font-size: 1.7em;}
  
    .left, .right{display: inline-block; width: 55%;}
    .right{float:right; width: 40%;
      img{margin-bottom: 40px;}
      // p{ text-align: center;}
    }
    
    .text{width:75%; @include center-block(); width:80%;}
    .logoLine{@include sec-default(); text-align: center; padding: 40px 0; margin-top:30px;
      @include sec-bgimage('../assets/images/cater-img10.jpg'); background-size: cover;
      position: relative; z-index: 0; &::before{@include before(); background-color: rgba($white,.8);}
      
      .restLogo{display: inline-block; width:30%;}
      // .restLogo {float:left;
      //   &.rlVG{margin-left: 8%;}
      //   &.rlYoho{width:25%; float:right; margin-top:7px;}
      // } 

      .restLogo {float:none; margin: 0 3%;
        &.rlYoho{width:25%; margin-top:7px;}
      } 


    }
    
    h3{color: $color1; font-size: 1.4em; 
      color:$melon; font-weight: 800;
      font-family: $nanum; letter-spacing: .3px; 
    }
    
    @include bp(tb) {margin-top:0; padding: 60px 0;
      h3{font-size: 1.25em; }
      .left .text{width:85%;}
      .right{
        p{width:99%; font-size:.95rem;}
      }
    }
    
    @include bp(tn) {padding: 45px 0 30px;
      h1{font-size: 1.5em; margin-bottom: 10px;}      
      h3{font-size: 1.1em; }
      .left, .right {width:85%; @include center-block();
        .text{@include sec-default();}
        .logoLine { padding: 20px 0;
          .insideXl{width:80%;}
        }
      }
      .right{
        img{display: none;}
        p{@include sec-default(); margin-top:30px;}
      }
    }
    
    @include bp(oc) {padding: 30px 0 20px;
      h1{font-size: 1.3em;}
      h3{font-size: 1em; }
      .left, .right {width:95%;
        .logoLine {
          .insideXl{width:90%;}
        }
      }
    }
  } 
  
  .homeWhoV2b {padding:40px 0; // display:none;
    .intro{@include sec-default(); text-align: center; margin-bottom: 25px;}
    .right{margin-top:45px;}
    
    @include bp(tb) {
      .intro{margin-bottom: 15px;}
    }
  }
  

  .homeQuote {@include sec-default(); padding:80px 0; margin:60px 0 70px; background-color: $color1-lt;
    h3{font-size: 1.8em; font-weight: 800; color: $color1; font-family: $nanum; letter-spacing: .3px; }
    p{margin-bottom:30px;}
    .modalBtn{@include link(30%, $white); background-color: $color1;}
    
    img, .text{display: inline-block; width:20%;}
    .text{width:75%; float:right;}
    
    @include bp(tb) {margin:0px; padding: 60px 0;
      h3{font-size: 1.5em; }
    }
    
    @include bp(tn) { padding: 30px 0;
      h3{font-size: 1.25em; letter-spacing: 0; }
    }
    
    @include bp(oc) { padding: 20px 0;
      h3{font-size: 1.1em;}
      h3, p{margin-bottom:15px;}
      img{display: none;}
      .text{@include sec-default(); text-align: center;}
      .modalBtn{font-size: 14px; width:40%;}
    }
  }
  
  .homeLocations {@include sec-default(); text-align: center; padding:0px 0 100px;
    .cTextBox{ padding: 20px;
      .cImage{@include sec-default(); margin-bottom: 15px;}
      img{@include center-block(); height: 100px; width:auto;}
    }
    .rBox.yoho .cTextBox img{height:80px; margin:10px auto;}
    .rBox.marker .cTextBox img{height:120px;}
    
    h3{color: $color2; text-transform: uppercase; font-weight: 800; font-size: 1.7em; font-family: $nanum; letter-spacing: .3px; }
    .borderTitle2x h3{ text-transform: none; font-family: $nanum; letter-spacing: .3px; font-size: 1.9em; margin-bottom: 50px;}
    
    // full-width
    .rBox{@include sec-default(); padding: 20px 0;
      .diningRoom, .cTextBox{display: inline-block;}
      .diningRoom {float: left; width: 55%;}
      .cTextBox {width: 40%; padding: 0; margin-top:50px;}
      
      &.vg, &.marker{
        .diningRoom{float:right;}
      }
    }
    
    @include bp(tb) { padding: 40px 0;
      .borderTitle2x h3{font-size: 1.5em; margin-bottom: 20px;}
      
      .rBox{ padding: 20px 0;
        .diningRoom {width: 55%;}
        .cTextBox {width: 40%; margin-top:20px;}
      }
    }
    
    @include bp(tn) { padding: 30px 0 10px;
      .borderTitle2x h3{font-size: 1.2em; margin-bottom: 20px; letter-spacing: 0;}
      
      .rBox{ padding: 20px 0; margin-bottom: 30px;;
        .diningRoom {width: 40%;}
        .cTextBox {width: 55%; margin-top:0px; padding:0;
          img {height:80px;}
        }
        &.yoho .cTextBox img{height:65px; margin:0 auto;}
      }
    }
    
    @include bp(oc) { padding: 30px 0 10px;
      .borderTitle2x h3{font-size: 1.1em;}
      
      .cTextBox{padding:0;}
      .rBox{
        .diningRoom {width: 80%; @include center-block(); margin-bottom: 15px;}
        .cTextBox {@include sec-default();}
        &.vg, &.marker {
          .diningRoom{float:none;}
        }
      }
    }
  }
}
