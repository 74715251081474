@import "../../assets/global";
@import "../../assets/variables";
@import "../../assets/mixins";

.Menus{@include sec-default();
  h1{font-family: $nanum;}
  .MenuIntro{@include sec-default(); background-color: $color1-lt; padding: 40px 0 55px; text-align: center;
    .wcgLink{background-color: $color2;}
  }
  
  .MenuSec1{@include sec-default(); padding: 60px 0;
    .menuList{text-align: center;}
  }
    
  // cover img bg with quote box
  .menuListItem{display: inline-block; position: relative;
    width: 28.5%; height:220px; margin: .5%;
    @include sec-bgimage2(); background-size: cover; background-position: center center;

    h4{font-weight: 800; font-family: $nanum; background-color: $white;
      position: absolute; width:90%; left: 5%; bottom:0px;
    }
    
    &.corp{background-image: url('../../assets/images/menuCorporateLunch.jpg');}
    &.luncheon{background-image: url('../../assets/images/menuLuncheon.jpg');}
    &.wedding{background-image: url('../../assets/images/menuWeddingReception.jpg');
      width:40%; float:right; height:455px;
    }
    &.cocktail{background-image: url('../../assets/images/menuCocktailDinner.jpg');}
    &.taco{background-image: url('../../assets/images/menuTacoBar.jpg');}
    &.quote{background-color: $melon; width: 99%; height:70px; padding:0; vertical-align: top; z-index: 0;
      &:hover {cursor: pointer; opacity: .9;}
      h4{background-color: transparent; bottom: 30%; margin: 0; 
        width:85%; left:7.5%; letter-spacing: .5px;
        color: $white; @include tshadowl(rgba($black,.4));
      }
    }
    &.quote:before{@include before(); border: 4px solid rgba($white, .5); 
      width:97.2%; left: 1%; height:75%; top: 6%;
      height:70%; top: 8%;
    }
    
    // KPKP update 3-31 // THIS NEEDS TO BE CLEANED UP WITH OG CODE IF FINAL STYLE
    img{display: none;}
    z-index: 0;
    &::before{@include before(); background-color: rgba($black, .2);}
    &.quote:before{background-color: transparent;}
    
    h4{color: $white;@include tshadowc(0px 1px 2px, rgba($black,.9)); 
      margin-bottom:5px; font-size:1.2rem;letter-spacing: .5px;
      background-color: transparent;
    }
    
    @include bp(tb) {
      &::before{background-color: rgba($black, .3);}
      h4{font-size:1.1rem;}
      &.quote h4{font-size: 1rem;}
    }
    // KPKP update 3-31
  }
  
  @include bp(tn) {
    .MenuSec1{padding: 30px 0;}
    .menuListItem{width:49%; height:150px;
      h4{margin-bottom:10px;}
      
      &.wedding{width: 99%; @include center-block(); height: 150px;}
      &.quote{height:auto; padding: 20px 0;text-align: center;
        h4{position: relative; bottom: auto; left:auto; width:95%; @include center-block();}
      }
    }
  }
  
  @include bp(oc) {
    .MenuIntro .wcgLink{width:60%;}
    .MenuSec1{ padding: 20px 0;}
    .menuListItem, .menuListItem.wedding{@include sec-default(); height:120px; margin:0 0 15px;
      h4{margin-bottom:10px;}
      &.quote{@include sec-default(); margin: 0;
        &::before{display: none;}
      }
    }
  }
}