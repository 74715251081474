$cream: #fbf9f8;
$f9: #f9f9f9;
$f8: #f8f8f6;
$fa: #fafaf8;
$fa2: darken($fa, 1%);
$f0: #f0f0f0;
$f3: #f3f3f3;
$f2: #f2f2f2;
$df: #dfdfdf;
$white: #fff;
$black: #000;

// $color1: #2893AC;
$color1: #3597af;
$color1-lt:#eaf4f7;
$color2: #082a54;
$color2-lt:#e7e9ee;
$melon: #f86157;
$melon-lt: #feefee;
$red: #ff0000;

$text-lt:#e7e9ee;
$border: 1px solid rgba($black,.1);


$quick: 'Quicksand', sans-serif; // too rounded when bold
$dance: 'Dancing Script', cursive;
$nanum: 'Nanum Myeongjo', serif;

$body: $quick;
$semi: 500;
$light: 300;