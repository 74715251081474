@import '../../assets/global';
@import '../../assets/variables';
@import '../../assets/mixins';

.FooterAlt {@include sec-default();
  .Split {@include sec-default(); background-color: $color1; position: relative; z-index: 0; padding:20px 0;
    &::before{@include before(); background-color: $color2; width:50%;}
    .mailHlf, .contactHlf {display: inline-block; width:48%; }
    .mailHlf{width:42%; float: left; text-align: center; 
      h4, p {color: $white;}
      h4{font-weight: bold; font-family: $nanum; letter-spacing: .3px; font-size:1.3rem;}
      h4{margin-top:15%; margin-bottom: 10px;}
      
      .MCsignup{width:80%; margin-left:7%;}
      form{@include sec-default(); margin-bottom: 20px; background-color: $color1-lt; padding: 10px 20px !important; }
      .MCemail {@include sec-default();width:98%; margin-bottom: 5px; font-size: 15px; line-height: 1.3em; padding: 3px 0 3px 5px;}
      .wcgLink {background-color: $color1; width:120px; float: left; text-align: center;}
    }
    .contactHlf {float:right; margin-right: 4%; padding: 30px 0 20px;
      .cBox {@include sec-default(); padding: 15px 0 25px 50px; background-color: $white;}
      h4{width:90%; font-weight: bold; border-bottom: 2px solid $black; text-transform: uppercase;}
      p b{font-size:15px}
      li{display: inline-block; margin-right:10px;}
    }
    
    @include bp(tb) {
      .mailHlf h4{font-size: 1.1rem;}
    }
    
    @include bp(tn) {
      .mailHlf .MCsignup{width:90%; margin-left:-2%;}
    }
    
    @include bp(oc) {padding: 0;
      &::before{@include before(); background-color: $color2; height:60%;}
      .mailHlf, .contactHlf {@include sec-default();}
      .mailHlf {
        h4{font-size: 1rem;}
        .MCsignup{margin-left:0;}
      }
      .contactHlf {
        .cBox {padding: 15px 0;}
        h4, p, ul{width:87.5%; margin-left:5%;}
      }
    }
  }
}


