@import "../../assets/global";
@import "../../assets/variables";
@import "../../assets/mixins";

.About{@include sec-default();
  .abCustom {@include sec-default(); padding:80px 0;
    position: relative; z-index: 0; &::before{@include before(); height: 380px; background-color: $color1-lt;}
    .left, .right{display: inline-block; width: 43%;}
    .right{float:right; width:48%;}
    
    h1, h3{color: $color2; text-transform: none; font-weight: 800; font-size: 3em; margin:5px 0 80px; font-family: $nanum; line-height: 1.5em; }
    h1 span, h3 span{ color: $melon; letter-spacing: 5px;
      font-family: $dance; font-size: 1.5em; line-height: 1.1em;
    }
    h4{color: $color1; margin:20px 0 0; font-weight: 800;font-family: $nanum; }
    p{margin-top:5px;}
    img{margin-bottom:10px;}
    
    .wcgLink{width:160px;}
    
    @include bp(tb) {padding:60px 0 40px;
      &::before{height: 250px;}
      h1, h3{font-size:2.2em;}
    }
    
    @include bp(tn) {padding: 15px 0 25px; text-align: center;
      &::before{height: 75px;}
      h1, h3{font-size:1.5em; margin:0 0 30px;}
      h1 span, h3 span{letter-spacing: 2px;}
      br{display: none;}
      
      .left, .right{width:85%; @include center-block();}
      .right img{display:none;}
    }
    
    @include bp(oc) {padding-top:10px;
      &::before{height: 60px;}
      h1, h3{font-size:1.2em; margin:0 0 30px;}
      h1, span, h3 span{letter-spacing: 0px;}
      
      .left, .right{width:95%;}
    }
    
    @include bp(xs) {
      // &::before{height: 85px;} 
    }
  }
  
  .abInfo {@include sec-default(); padding:30px 0 60px;
    .abiIntro {@include sec-default(); margin-bottom: 50px;
      // height:200px; @include sec-bgimage('./images/tray.jpg');
      height:230px; @include sec-bgimage('./images/team.jpg'); background-position: left center; background-size:70%;
      .text{width:30%; text-align: center; float:right; margin-right: 1.5%;}
      h3 {font-size:1.7rem; font-weight: 800; color: $color2; // margin-top:15%; 
        margin-top:18%;
        font-family: $nanum; letter-spacing: .3px; line-height: 1.1em;
        span{ color: $color1; font-size:1.4rem; font-family: inherit;}
        b{text-decoration: underline;}
      }
    }
    
    .abiBlock1, .abiBlock2 {display: inline-block; width:63%;}
    .abiBlock2{width:33%; float: right;}
    .abiBlock3{@include sec-default(); margin-top:30px;
      p{display: inline-block; width: 29%;
        &:nth-child(3) {margin-left: 3%;}
        &:last-child{float:right; width:38%; margin-right:-2%;}
      }
      p{float:left;}
    }
    h4{font-size:1.2rem; font-weight: 800; font-family:$nanum; color: $color1; margin-bottom: 0;}
    
    @include bp(tb) {padding: 0 0 30px;
      .abiIntro {background-size:65%; height:180px;
        .text{margin-right:5%;}
        h3 {font-size:1.4rem;
          span{font-size:1.2rem;}
        }
      }
      .abiBlock3{margin-top:0px;}
      h4{font-size:1rem;}
    }
    
    @include bp(tn) {padding: 0 0 15px;
      .abiIntro {background-size: cover; height:auto; padding: 20px 0; position: relative; z-index: 0;margin-bottom: 25px;
        &::before{@include before(); background-color: rgba($black,.75);}
        .text {width:90%; @include center-block();}
        
        h3 {font-size:1.3rem; margin:0; color: $white; text-shadow: 0px 1px 2px rgba($black,.9);
          span{font-size:1.2rem; color: lighten($color1, 15%);}
        }
      }
      .abiBlock1, .abiBlock2, .apiBlock3{@include sec-default(); 
        p{margin:5px 0 12px;}
      }
      .abiBlock3 {
        p, p:nth-child(3), p:last-child {@include sec-default(); margin:5px 0 10px;}
      }
    }
  }
  
  .homeQuote {@include sec-default(); padding:80px 0;  background-color: $color1-lt;
    h3{font-size: 1.8em; font-weight: 800; color: $color1; letter-spacing: -1px; 
      font-family: $nanum; letter-spacing: .3px;
    }
    p{margin-bottom:30px;}
    .modalBtn{@include link(30%, $white); background-color: $color1;}
    
    img, .text{display: inline-block; width:20%;}
    .text{width:75%; float:right;}
    
    @include bp(tb) {margin:0px; padding: 60px 0;
      h3{font-size: 1.5em; }
    }
    
    @include bp(tn) { padding: 30px 0;
      h3{font-size: 1.25em; letter-spacing: 0; }
    }
    
    @include bp(oc) { padding: 20px 0;
      h3{font-size: 1.1em;}
      h3, p{margin-bottom:15px;}
      img{display: none;}
      .text{@include sec-default(); text-align: center;}
      .modalBtn{font-size: 14px; width:40%;}
    }
  }
}

