@import '../../assets/global';
@import '../../assets/variables';
@import '../../assets/mixins';

.Header {@include sec-default();text-align: center;
  button {padding:0; border:0; line-height:1em;}
  svg {width:30px; height:30px;}
  
  .bigRow{color: $color2; background-color: transparent; margin-bottom: 10px; 
    img {width:175px;}
    .navLeft, .navRight{margin-top:40%;}
    li {padding: 0 15px;}
    button, a {font-family: $body; color: $color2; font-weight:500; min-height: auto; text-transform: none; font-size: inherit;}
    button{min-width:auto;}
    li.email{padding-left:0;}
    
    li.social {display: none;
      &.fb{padding:0;}
      a{padding: 0;}
      svg {width:20px; height:20px;} 
    }
    
    @include bp(tb) {
      button, a {font-size:16px;}
    }
    
    @include bp(br) {
      button, a {font-size:15px;}
    }

    @include bp(og) {
      li.phone, li.email {display: none;}
    }
  }
}

.SideNavList{padding:40px 15px 10px 25px; width:300px; margin-top:40%;
  .closeIcon, li a, li button{color: $color2; font-family: $body; font-size:20px; padding:0; }
  .closeIcon {position: absolute; right:20px; top:15px; cursor: pointer; font-weight: normal;}
  .sideContact{display: none;}
  li {@include sec-default(); margin: 10px 0 10px;
    a, button{padding-left:15px;font-family:$nanum; font-weight:bold;}
    
    button{background-color: $color1; color: $white; padding:7px 20px; font-weight:bold; @include tshadowc(0px 1px 2px, rgba($black,.2));
      &:hover{cursor: pointer; background-color: rgba($color1, .85);}
    }
  }
  
  
  @include bp(tn){
    .closeIcon, li a, li button{font-size:18px;}
  }
  
  @include bp(og) {margin-top: 7%; padding:10px 15px 20px 25px;
    .sideContact {@include sec-default();}
    li a, li button{font-size:17px;}
    li a{padding-left: 5px;}
  }
  
  @include bp(sm) {width:250px;
    li a, li button{font-size:16px;}
  }
}
