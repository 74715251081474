@import '../../assets/global';
@import '../../assets/variables';
@import '../../assets/mixins';

.quoteModal {
  .customModal{width:60%; @include center-block();}
  .modalHead{text-align: center; margin-bottom:20px; padding-top:0;
    h4{margin-bottom: 7px; font-weight:800; font-size: 1.5rem; font-family: $nanum; letter-spacing: .3px; color:$color2;}
    p{color: $red; font-size:.9em; }
  }
  .formGroup, .formGroupLine, .formGroupMid {
    // @include sec-default(); width:94%; 
    margin: 10px 3% 20px;
    p{margin-bottom: 3px; font-size: 16px;}
    p span{color:$red; font-size:16px;}
    input, textarea {width:100%; padding: 5px 0 5px 5px; font-size: 14px; line-height: 1.3em;
      border:0; border-bottom: 2px solid rgba($black, .3); border-radius:0;
    }
    textarea{min-height:100px;}
    input {height:30px;}
  }
  .formGroup, .formGroupLine, .formGroupMid{float: none;}
  .formGroupLine{display: inline-block; width: 42%;}
  .formGroupMid {width:90%;}
  .formGroupSubmit{border-radius: 0; margin-right: 6%;}
  
  @include bp(tb) {
    .customModal{width:90%;}
    .wcgLink{border-radius: 0 !important; background-color: $color1 !important; }
  }
  
  @include bp(br) {
    .modalHead h4{font-size:1.2rem;}
    .ukmb{padding:15px;}
    .formGroup, .formGroupLine, .formGroupMid {width:85%; @include center-block();
      input, textarea{padding: 2px 0;}
      input {height:20px;}
      input.inputDate{padding-bottom: 4px;}
    }
    hr{margin-top:0;}
  }
  
  @include bp(oc) {
    .customModal{width:95%;}
    .modalHead h4{font-size:1.1rem;}
    .formGroup, .formGroupLine, .formGroupMid {width:95%;}
  }
  
  @include bp(sm) {
    .formGroup, .formGroupLine, .formGroupMid {width:100%;}
  }
  
}