@import url('https://fonts.googleapis.com/css?family=Quicksand:400,500,600|Dancing+Script:400,700|Nanum+Myeongjo:400,700,800&display=swap');
@import './variables.scss';
@import './mixins.scss';

body{margin:0;}
html {margin:0;
  font-family:$body;line-height: 1.5em; font-size: 17px;
  h1, h2, h3, h4, h5, p, li, a, span {color: $black; line-height: 1.5em; font-family:$body;
    &.color1 {font-family: $color1;}
    &.color2 {font-family: $color2;}
    
    &.bmargin0 {margin-bottom: 0;}
  }
  p,li{font-weight: 400;}
  p{font-size: 1rem; margin-top:7px;}
  
  h1, h2 {line-height: 1.3em;}
  h1{font-size: 1.9em; font-weight:800;}
  h2{font-size:1.5rem; margin-top:20px;}
  h3{font-size: 1.17rem;}
  h4{font-size:1.1rem;}
  
  br.brTb {display: none;}
  
  @include bp(tb) {
    p{font-size:.95em;}
    h4{font-size:1rem;}
    br.brLg {display: none;}
    br.brTb {display: block;}
  }
  
  @include bp(tn) {
    p{font-size:.9rem;}
    h4{font-size:.95rem;}
  }
}

.inside, .insideAlt, .medDef, .med2Def, .med3Def, .insideXl, .insideNrw{ @include center-block(); max-width:1600px;}
.inside{width: 85%;}
.insideXl {width: 90%;}
.insideAlt {width: 80%;}
.medDef {width:1080px;}

@media (max-width:1235px) {
  .medDef {width:90%;}
}

@include bp(xl) {
  .med2Def{width: 940px;}
  .med3Def{width: 850px;}
}

@include bp(tb) {
  .med2Def, .med3Def {width:85%;}
}

@media screen and (min-width:823px){
  .insideNrw{width:785px;}
}

@include bp(tn) {
  .insideNrw{width:90%}
}

.boxedBody {width: 90%; @include center-block();}

.container {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;

  @media (min-width: 576px) {max-width: 556px;}
  @media (min-width: 768px) {max-width: 748px;}
  @media (min-width: 992px) {max-width: 972px;}
  @include bp(dt) {max-width: 1180px;}
  @include bp(ph) {max-width: 90%;}
}

.fullRow{@include sec-default();}
.borderRow{@include sec-default(); padding: 60px 0; margin:50px 0;
  border-top:4px double $black;
  border-bottom:4px double $black;
  @include bp(tb) {margin:25px 0; padding:40px 0 30px;}
}

.borderTitle, .borderTitle2 {@include sec-default(); margin-bottom: 40px;
  position: relative; z-index:0; 
  &::before{@include before(); height:10px; top:25%; 
    border-top:1px solid rgba($color1, .4);
    border-bottom:1px solid rgba($color1, .4);
  }
  h3{position: relative; z-index:0;
    &::before{@include before(); background-color: $white; width:30%; left:35%; }
  }
}
.borderTitle2::before{border-color: rgba($color2, .4);}

.centerBlock{ @include center-block();}
.bmargin0 {margin-bottom: 0;}
.tmargin0 {margin-top: 0;}
.textCenter {text-align: center;}
.tpadding4{padding-top:40px;}
.tpadding6{padding-top:60px;}

.spacer1 {@include sec-default();height:10px;}
.spacer2 {@include sec-default();height:20px;}
.spacer3 {@include sec-default();height:30px;}
.spacer4 {@include sec-default();height:40px;}
.spacer5 {@include sec-default();height:50px;}
.spacer6 {@include sec-default();height:60px;}

hr{border-color: rgba($black,.1);
	&.hlf {width: 50%;}
	&.less {width: 25%;}
}
.clearfix::after, .clearfixBtn::after {
  content: "";
  clear: both;
  display: table;
}
.clearfix.btn, .clearfixBtn {@include sec-default(); margin-top:20px;}
.clearBorder, .clearBorderWhite, .clearBorderLeft{@include sec-default(); height:30px; position: relative; z-index: 0; margin:0px 0 15px;
  &::before{@include before(); width:1px; left:49.7%; background-color: rgba($black,.2);}
  
  @include bp(oc) {height:20px;}
}
.clearBorderWhite::before{background-color:rgba($white, .3);}
.clearBorderLeft::before{left:12%;}

.wcgLink {@include link(30%, $white); background-color: $color1; @include tshadowl(rgba($black, .3));
  &.altColor{background-color: $color2;}
  &.invColor{background-color: $white; color:$color1; border:2px solid $color1; @include tshadowl(rgba($black, .1));}
  &.inline{display: inline-block; width:auto; margin-bottom:20px;
    &:last-child {margin-left: 15px;}
  }
  &.center{@include center-block();}
  &.threeLine{display: inline-block; width:auto; margin-bottom:20px; padding:10px; font-size:14px;
    &.invColor {margin: 0 7px 20px}
  }

  @include bp(tb) {font-size:14px;}
}
.textLink{font-weight: bold;
  &.und {text-decoration: underline; }
}

.visuallyHidden {@include visuallyHidden();}
.imgResponsive {max-width: 100%; height: auto; display:block;}
ul {margin:0; list-style-type: none; padding-left: 0;}
ul.disc li{list-style-type: disc;}

a {text-decoration: none;
  &:hover {opacity: .7; text-decoration: none; @include ease(.3s);}
}

button{background: none; border:none; border-radius:0; font-size:inherit; color:inherit; font-weight:inherit; letter-spacing: inherit;}